




























import Store from '@/models/Store.model';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ContactPersonComponent extends Vue {
  @Prop({ required: true })
  public store!: Store;

  private properties = [
    { title: 'STORE.CONTACT_PERSON.NAME', value: 'name', icon: 'fas fa-user' },
    { title: 'STORE.CONTACT_PERSON.EMAIL', value: 'email', icon: 'fas fa-envelope' },
    { title: 'STORE.CONTACT_PERSON.PHONE', value: 'phone', icon: 'fas fa-phone' },
  ]
}
